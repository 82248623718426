/* Custom styles for the form */
.custom-form {
  background-color: #fff;
  padding: 2rem;

  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 20rem; /* Adjust the width as needed */
}
.text-center {
  text-align: center;
}
.custom-input {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  margin-top: 0.25rem;
}

.custom-button {
  background-color: #4caf50;
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #45a049;
  }
}
.custom-form-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
.custom-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.custom-form {
  width: 300px; /* Adjust the width as needed */
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.text-center {
  text-align: center;
}

.error-message {
  color: red;
}

.custom-input {
  width: 100%;
  padding: 8px;
  margin: 5px 0;
  box-sizing: border-box;
}

.loginbutton {
  width: 100%; /* Set the button width to 100% */
  padding: 10px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.loginbutton:hover {
  background-color: #2980b9;
}

.flex {
  display: flex;
}

.min-h-screen {
  min-height: 100vh;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.p-24 {
  padding: 1.5rem;
}

.max-w-md {
  max-width: 28rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.mb-4 {
  margin-bottom: 1rem;
}

.text-green-700 {
  color: #2f855a;
}

.bg-green-100 {
  background-color: #e6fffa;
}

.border-green-400 {
  border-color: #38a169;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.rounded-md {
  border-radius: 0.375rem;
}

.block {
  display: block;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.font-semibold {
  font-weight: 600;
}

.text-gray-600 {
  color: #4a5568;
}

.w-full {
  width: 100%;
}

img {
  width: 50px;
  margin: 7px;
}
header {
  border-bottom: 1px solid grey;
  padding: 0 2%;
}
.bg-black {
  background: black;
}
.p-2 {
  padding: 0.5rem;
}

.border {
  border-width: 1px;
}

.mt-1 {
  margin-top: 0.25rem;
}

.h-40 {
  height: 10rem;
}

.justify-end {
  justify-content: flex-end;
}

.bg-blue-500 {
  background-color: #4299e1;
}

.text-white {
  color: #fff;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.rounded-md {
  border-radius: 0.375rem;
}

.hover\:bg-blue-600:hover {
  background-color: #3182ce;
}
.formInput {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #a0aec0;
  border-radius: 0.25rem;
  margin-top: 0.25rem;
  box-sizing: border-box; /* Include padding and border in element's total width */
}

.formSelect {
  width: 100%;
  padding: 0.75rem; /* You can adjust this if needed */
  border: 1px solid #a0aec0;
  border-radius: 0.25rem;
  margin-top: 0.25rem;
  box-sizing: border-box; /* Include padding and border in element's total width */
}
.formButton {
  width: 100%;
  padding: 0.75rem;
  background-color: #4299e1;
  color: #fff;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  box-sizing: border-box; /* Include padding and border in element's total width */
}

.formButton:hover {
  background-color: #3182ce;
}
.excel-table {
  border-collapse: collapse;
  width: 100%;
}

.excel-table th,
.excel-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.excel-table th {
  background-color: #f2f2f2;
}
